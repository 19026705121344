import React from 'react';
import Loading from 'src/components/loadingPage';
import LabelLang from 'src/lang/components/LabelLang';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { showErrorPopup } from 'src/components/errorPopup';
import { hideDialog } from './redux/action';
import { getTokenFromPassword } from './redux/api';
import style from './style.scss';

const Submitting = () => (
  <div className={style.overlay}>
    <Loading />
  </div>
);

class SecurityDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      submitting: false,
    };
  }
  
  handleOnChangePassword = e => this.setState({ password: e.target.value });
  handleClose = () => this.props.hideDialog();
  handleConfirm = () => {
    this.setState({ submitting: true });
    getTokenFromPassword({ Email: this.props.email, Password: this.state.password, Scopes: 'constant-transfer'}).then(r => {
      this.setState({ submitting: false });
      document.cookie = 'transfertoken=' + r.Result.Token + '; expires=' + new Date(new Date().getTime()  + 60000).toGMTString();
      this.setState({ password: '' });
      this.props.callback();
      this.handleClose();
    }).catch((e) => {
      showErrorPopup({ error: e, message: <LabelLang id='app.common.wrongPassword' /> });
      this.setState({ submitting: false });
    });
  }

  render() {
    // eslint-disable-next-line
    const { show } = this.props;
    const { submitting } = this.state;
    return (
      <Modal
        show={show}
        onHide={this.handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {submitting && <Submitting />}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <LabelLang id="app.common.confirmPassword"/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input autoFocus value={this.state.password} onChange={this.handleOnChangePassword} type="password" />
        </Modal.Body>
        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
          <Button variant="primary" style={{ minWidth: '70px' }} onClick={this.handleClose}><LabelLang id="app.common.cancel"/></Button>
          <Button variant="secondary" style={{ minWidth: '70px' }} onClick={this.handleConfirm}><LabelLang id="app.common.confirm"/></Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({
  email: state.auth.profile.email,
  ...state.securityDialog
});

const mapDispatch = { hideDialog };
export default connect(mapState, mapDispatch)(SecurityDialog);
