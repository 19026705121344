import React from 'react';
import Loading from 'src/components/loading';
import cx from 'classnames';

const LoadingPage = ({
  className,
  ...loadingProps
}) => {
  return (
    <div className={cx('d-flex justify-content-center align-items-center w-100 h-100 p-3', className)}>
      <Loading {...loadingProps} />
    </div>
  );
};

export default LoadingPage;