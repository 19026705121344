import React from 'react';
import currentUser from 'src/utils/authentication';
import http from 'src/utils/http/httpRoot';
import httpCustom from 'src/utils/http/httpCustom';
import configureStore from 'src/redux/store';
import localStore from 'src/services/localStore';
import { APP, ERROR_CODE_OTP } from 'src/resources/constants/app';
import { LabelLang } from 'src/lang/components';
import { SESSION_EXPIRED } from 'src/screens/auth/redux/type';
import { showAlert } from 'src/screens/app/redux/action';

const store = configureStore();

export default class ConstantApi {

  constructor(model, httpModel = http) {
    this.model = model;
    this.httpModel = httpModel;
  }

  setHttpModel = baseUrl => {
    this.httpModel = httpCustom(baseUrl);
  };

  validation = () => {
    if (!this.model) {
      throw new Error('Inital Api must has the model name');
    }
  };
  options = async (method, data = null, withAuth = true ) => {
    this.validation();
    try {
      const options = {
        url: this.model,
        headers: {},
        method
      };
      if (data) options.data = data;
      // if (currentUser.isLogin()) {
      //   options.headers = { Authorization: 'Bearer ' + currentUser.getToken() };
      // }
      const state = store.getState();
      const userToken = state?.auth?.token || currentUser.getToken();
      const isAuth = !!userToken;
      if (isAuth && withAuth) {
        options.headers.Authorization = 'Bearer ' + userToken;
      }
      if (data && data.captcha) {
        options.headers.recaptcha = data.captcha;
      }

      const ipAdress = localStore.get(APP.IP_INFO)?.ip_address;
      if (ipAdress) {
        options.headers.Ip = ipAdress;
      }

      const countryCode = localStore.get(APP.IP_INFO)?.country_code;
      if (countryCode) {
        options.headers.Country = countryCode;
      }

      const res = await this.httpModel(options);
      return res;
    } catch (err) {
      if ([401].includes(err.status) && err?.code !== ERROR_CODE_OTP) {
        store.dispatch({ type: SESSION_EXPIRED });
        store.dispatch(
          showAlert({
            message: <LabelLang id="error.expiredSession" />,
            type: 'info',
            timeOut: 1000,
            callBack: () => {
              window.location.reload();
            }
          }),
        );
      }
      console.log(`ERROR FETCHING DATA FROM MODEL ${this.model}`, err);
      throw err;
    }
  };
  get = async (data, withAuth = true) => {
    return await this.options('GET', data, withAuth);
  };
  post = async (data, withAuth = true) => {
    return await this.options('POST', data, withAuth);
  };
  put = async (data, withAuth = true) => {
    return await this.options('PUT', data, withAuth);
  };
  delete = async (data, withAuth = true) => {
    return await this.options('DELETE', data, withAuth);
  };
  submitForm = async (data) => {
    console.log('COMMING SOON', data);
    return null;
  }
}
