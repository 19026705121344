import axios from 'axios';
import authentication from 'src/utils/authentication';
import localStore from 'src/services/localStore';
import {APP, REQUEST_TIMEOUT} from 'src/resources/constants/app';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const instanceFun = baseURL => {
  const instance = axios.create({
    baseURL,
    timeout: REQUEST_TIMEOUT,
    crossDomain: true,
    headers
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    config => {
      const ipAdress = localStore.get(APP.IP_INFO)?.ip_address;
      config.headers.Ip = ipAdress;

      const countryCode = localStore.get(APP.IP_INFO)?.country_code;
      if (countryCode) {
        config.headers.Country = countryCode;
      }

      return config;
    },
    error => Promise.reject(error)
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    response => {
      try {
        const { data } = response;
        return data;
      } catch (e) {
        return null;
      }
    },
    error => {
      try {
        if (error && error.response) {
          const {
            response: { status, data, statusText }
          } = error;
          const errorData = {
            error: true,
            status: status, // status code
            data: data,
            code: data?.code, // error code
            message: data?.message, // error msg
            statusText: statusText
          };
          if (status === 401) {
            authentication.removeAccessToken();
            return Promise.reject({
              sessionExpired: true,
              ...errorData
            });
          }

          console.warn('Response error', error);
          return Promise.reject(errorData);
        }
        if (error?.request) {
          return Promise.reject({
            error: true,
            message: error?.message,
            code: 'request_failed'
          });
        }
      } catch (e) {
        return Promise.reject(e);
      }
    }
  );
  return instance;
};


export default instanceFun;
